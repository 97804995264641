<template>
  <div id="categories-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <template v-if="!reordering">

        <vs-table ref="table" :data="filteredItems">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex flex-no-wrap">
              <div class="flex flex-wrap-reverse items-center">
                <router-link v-if="$acl.check('admin')" :to="{name: 'categoryCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
                  <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">{{$t('add_new')}}</span>
                </router-link>
                <div v-if="$acl.check('admin')" class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="toggleOrder">
                  <feather-icon icon="MenuIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2 text-base text-primary">{{$t('reorder')}}</span>
                </div>
                <div v-if="$acl.check('admin')" class="flex">
                  <input type="file" class="hidden" ref="uploadSliderImg" @change="uploadNewImage($event)" accept="image/*">
                  <div class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-warning border border-solid border-warning" @click="uploadImages('sales')">
                    <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                    <!-- <span class="ml-2 text-base">{{$t('sales')}}</span> -->
                    <span class="ml-2 text-base">Sale</span>
                  </div>
                  <div class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-warning border border-solid border-warning" @click="uploadImages('hits')">
                    <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                    <!-- <span class="ml-2 text-base">{{$t('hits')}}</span> -->
                    <span class="ml-2 text-base">Hit</span>
                  </div>
                  <div class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-warning border border-solid border-warning" @click="uploadImages('combos')">
                    <feather-icon icon="UploadIcon" svgClasses="h-4 w-4" />
                    <!-- <span class="ml-2 text-base">{{$t('comboProducts')}}</span> -->
                    <span class="ml-2 text-base">Combo</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mx-2">{{$t('sort')}}: </span>
                  <span class="capitalize mr-2">{{$t(current)}}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="getItems('all')">
                    <span>{{ $t('all') }}</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="getItems('active')">
                    <span>{{ $t('only_active') }}</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="getItems('passive')">
                    <span>{{ $t('only_passive') }}</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="getItems('parents')">
                    <span>{{ $t('only_parent') }}</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="getItems('leafs')">
                    <span>{{ $t('only_leaf') }}</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="getItems('except_leafs')">
                    <span>{{ $t('except_leaf') }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ currentx * filter.limit - (filter.limit - 1) }} - {{ categories.pagination.all - currentx * filter.limit > 0 ? currentx * filter.limit : categories.pagination.all }} of {{ categories.pagination.all }}</span>
                  <!-- <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ categories.data.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : categories.data.length }} of {{ queriedItems }}</span> -->
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                  <vs-dropdown-item v-for="opt in perPageOptions" :key="opt" @click="setLimit(opt)">
                    <span>{{ opt }}</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <div class="inline-block relative mb-4">
                <vs-input :placeholder="$t('search')" class="custom-search-input" v-model="searchQuery" />
                <feather-icon v-if="searchQuery.length" icon="TrashIcon" svgClasses="w-4 h-4 hover:text-danger stroke-current" style="position: absolute !important; top: 14px; right: 13px" class="ml-2" @click.stop="searchQuery = ''" />
              </div>
            </div>
          </div>
          <template slot="thead" v-if="!reordering">
            <vs-th class="">#</vs-th>
            <vs-th class="px-0"></vs-th>
            <vs-th class="pl-1 w-16">{{ $t('fields.image') | capitalize }}</vs-th>
            <vs-th class="pl-0">{{ $t('fields.code') }}</vs-th>
            <vs-th class="pl-0">Ady</vs-th>
            <vs-th class="hidden lg:table-cell">Название</vs-th>
            <vs-th class="hidden lg:table-cell">Name</vs-th>
            <vs-th>{{ $t('fields.parent') }}</vs-th>
            <vs-th>{{ $t('fields.active') }}?</vs-th>
            <vs-th>{{ $t('fields.type') }}</vs-th>
            <vs-th>{{ $t('fields.items') }}</vs-th>
            <vs-th>{{ $t('fields.actions') }}</vs-th>
          </template>
          <template slot-scope="{data}" v-if="!reordering">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3" :class="!data[indextr].isActive ? 'text-danger' : ''" :state="!data[indextr].canPublished ? 'danger' : ''">
              <vs-td class="font-medium">
                {{ data[indextr].priority }}.
              </vs-td>
              <vs-td class="pl-0 pr-2">
                <feather-icon v-if="data[indextr].isLeaf" icon="FeatherIcon" svgClasses="w-5 h-5 text-primary" />
                <feather-icon v-else-if="!data[indextr].isLeaf && data[indextr].parentId" icon="ItalicIcon" svgClasses="w-5 h-5 text-primary" />
                <feather-icon v-else-if="!data[indextr].isLeaf && !data[indextr].parentId" icon="BookIcon" svgClasses="w-5 h-5 text-primary" />
              </vs-td>
              <vs-td class="pl-2 w-16">
                <div class="img w-16 h-16 rounded overflow-hidden">
                  <img v-if="tr.image_isAdded" :src="`${$baseUrl}/categories/${tr.uuid}.webp`" class="w-full h-full" alt="">
                  <span v-else>No img</span>
                </div>
              </vs-td>
              <vs-td class="pl-0" :data="data[indextr].code">
                {{ data[indextr].code }}
              </vs-td>
              <vs-td class="pl-0" :data="data[indextr].name_tm">
                {{ data[indextr].id }}/{{ data[indextr].name_tm }}
              </vs-td>
              <vs-td :data="data[indextr].name_ru" class="hidden lg:table-cell">
                {{ data[indextr].name_ru }}
              </vs-td>
              <vs-td :data="data[indextr].name_en" class="hidden lg:table-cell">
                {{ data[indextr].name_en }}
              </vs-td>
              <vs-td :data="data[indextr].parentId">
                {{ getName(data[indextr].parentId) }}
              </vs-td>
              <vs-td :data="data[indextr].canPublished">
                <vs-chip :color="tr.isActive ? 'primary' : 'danger'">
                  <feather-icon v-if="tr.isActive" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td :data="data[indextr].type">
                <vs-chip :color="tr.type === 'both' ? 'success' : tr.type === 'express' ? 'primary' : 'warning'">
                  {{ $t(tr.type) }}
                </vs-chip>
              </vs-td>
              <vs-td :data="data[indextr].product_count">
                {{ data[indextr].product_count }}
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EyeIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]" class="" title="View category details" @click.stop="showData(tr.uuid)" />
                <feather-icon v-if="$acl.check('admin')" icon="EditIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Edit category details" @click.stop="editData(tr.uuid)" />
                <!-- <feather-icon v-if="$acl.check('admin')" icon="RepeatIcon" :svgClasses="[tr.canPublished ? 'stroke-current': 'text-danger', 'w-5', 'h-5', 'hover:text-primary' ]"  class=" ml-2" title="Change category status" @click.stop="changeStatus(tr.uuid, tr.isActive)" /> -->
                <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row class="mt-4">
          <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
        </vs-row>
      </template>
      <div v-else>
        <vs-list>
          <vs-list-header :title="$t('reorder')" color="primary"></vs-list-header>
          <draggable :list="categories.data" group="people" class="p-2 cursor-move">
            <div v-for="(listItem, index) in categories.data" :key="index" class="py-2" style="border-bottom: 1px solid #666666;">
              <span class="pr-3">{{ index + 1 }} )</span> 
              <feather-icon v-if="!listItem.parentId" icon="BookIcon" svgClasses="w-5 h-5 text-primary align-text-top" />
              <feather-icon v-else-if="listItem.isLeaf" icon="FeatherIcon" svgClasses="w-5 h-5 text-primary align-text-top" />
              <feather-icon v-else icon="ItalicIcon" svgClasses="w-5 h-5 text-primary align-text-top" />
              <span class="px-3">{{ listItem.code }} -</span>
              <span class="font-semibold" :class="!listItem.isActive || !listItem.canPublished ? 'text-danger' : ''" style="font-size: 14px;">{{ listItem[`name_${$i18n.locale}`] }}</span>
            </div>
            <!-- <vs-list-item v-for="(listItem, index) in categories" :key="index" :title="listItem[`name_${$i18n.locale}`]">
              <vs-avatar slot="avatar" :text="listItem[`name_${$i18n.locale}`]" />
            </vs-list-item> -->
          </draggable>
        </vs-list>
        <vs-row>
          <vs-button type="filled" @click.prevent="toggleOrder" class="ml-auto mr-4">{{ $t('submit')}}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="cancelToggle" class="mr-auto ml-4">{{ $t('cancel')}}</vs-button>
        </vs-row>
      </div>
    </vx-card>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      categories: { data: [], pagination: {} },
      reordering: false,
      isLoading: true,
      isMounted: false,
      current: this.$route.query.current || 'all',
      perPageOptions: [10, 15, 20, 50, 100],
      currentx: Number(this.$route.query.page || 1),
      newImage: null,
      uploading: '',
      searchQuery: this.$route.query.q || '',
      filter: {
        limit: Number(this.$route.query.limit || 20),
        offset: Number(this.$route.query.limit || 20) * (Number(this.$route.query.page || 1) - 1),
        keyword: this.$route.query.q || '',
        isActive: null,
        canPublished: null,
        isParent: this.$route.query.current === 'parents' ? true : null,
        isLeaf: this.$route.query.current === 'leaf' ? true : this.$route.query.current === 'except_leaf' ? false : null,
        type: null
      }
    }
  },
  watch: {
    filter: { handler () { this.fetchData() }, deep: true },
    searchQuery (newVal) {
      if (newVal.length > 2) {
        this.filter.keyword = newVal
        this.currentx = 1
        const query = { ...this.$route.query, q: String(newVal) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      }
      if (!newVal) {
        this.filter.keyword = ''
        const query = { ...this.$route.query, q: null }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      }
    }
  },
  computed: {
    pages () { return this.categories.pagination && this.categories.pagination.all ? Math.ceil(this.categories.pagination.all / this.filter.limit) : 1 },
    filteredItems () { return this.categories.data },
    currentPage () {
      if (!this.isLoading) { return this.$refs.table.currentx }
      return 0
    },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.categories.data.length },
    getName () {
      return (cId) => {
        const cat =  this.categories.data.find(cat => cat.id === cId)
        if (cat) { return cat[`name_${this.$i18n.locale}`] }
        return cId
      }
    }
  },
  async created () {
    await this.fetchData()
    this.isLoading = false
  },
  methods: {
    async fetchData () {
      await this.$http.get('/categories', { params: this.filter }).then(response => {
        this.categories = response.data
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
    },
    uploadImages (img) {
      this.uploading = img
      this.$refs.uploadSliderImg.click()
    },
    uploadNewImage (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.newImage = e.target.result
          this.$http.post('/upload-static-images', { name: this.uploading, photo: this.newImage }).then(() => {
            this.notify('Success', this.$t('notify.success'), 'Image successfully uploaded!')
            this.fetchData()
          }).catch(err => {
            this.notify('Error', this.$t('notify.error'), err.message)
            this.newImage = null
          })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    deleteData (id) {
      this.$http.delete(`/categories/delete/${id}`).then(() => {
        this.notify('Success', this.$t('notify.success'), `${this.$t('category')} ${this.$t('notify.sDeleted')}`)
        this.fetchData()
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
    },
    editData (id) { this.$router.push(`/categories/${id}/edit`) },
    showData (id) { this.$router.push(`/categories/${id}`) },
    async changeStatus (id, status) {
      await this.$http.patch(`/categories/edit/${id}`, { isActive: !status }).then(() => {
        this.fetchData()
        this.notify('Success', this.$t('notify.success'), `${this.$t('category')} ${this.$t('notify.sUpdated')}`)
      }).catch(err => { this.notify('Error', this.$t('notify.error'), err.message) })
    },
    toggleOrder () {
      if (this.reordering) {
        const newList = this.categories.data.map((el, idx) => { return { id: el.id, priority: idx + 1 } })
        this.$http.patch('/categories/reorder', newList).then(() => { this.fetchData() })
        this.reordering = false
      } else {
        this.reordering = true
      }
    },
    cancelToggle () {
      this.reordering = false
    },
    getItems (e) {
      this.current = e
      const query = { ...this.$route.query, current: e }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ query })
      }
      this.currentx = 1
      this.filter.offset = 0
      this.filter.isActive = null
      this.filter.canPublished = null
      this.filter.isLeaf = null
      this.filter.isParent = null
      this.filter.type = null
      switch (e) {
      case 'all':
        break
      case 'active':
        this.filter.isActive = true
        this.filter.canPublished = true
        break
      case 'passive':
        this.filter.isActive = false
        this.filter.canPublished = false
        break
      case 'parents':
        this.filter.isParent = true
        break
      case 'leafs':
        this.filter.isLeaf = true
        break
      case 'except_leafs':
        this.filter.isLeaf = false
        break
      default:
        break
      }
    },
    paginateTo (e) {
      if (this.isMounted) {
        this.filter.offset = this.filter.limit * (this.currentx - 1)
        const query = { ...this.$route.query, page: String(e) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      } else {
        this.isMounted = true
      }
    },
    setLimit (e) {
      if (this.filter.limit !== e) {
        this.currentx = 1
        this.filter.offset = 0
        this.filter.limit = e
        const query = { ...this.$route.query, limit: String(e) }
        if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
          this.$router.replace({ query })
        }
      }
    },
    notify (type, title, text) {
      this.$vs.notify({
        title,
        text,
        iconPack: 'feather',
        icon: type === 'Success' ? 'icon-check-circle' : 'icon-alert-circle',
        color: type === 'Success' ? 'primary' : 'danger'
      })
    }
  }
}
</script>

<style lang="scss">
#categories-view {
  .vs-con-table {
    .img {
      background-color: #cdcdcd;
    }
    .product-name {
      max-width: 23rem;
    }
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      // margin-left: 1.5rem;
      // margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
}
</style>
